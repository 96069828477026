<template>
  <b-overlay :show="show" rounded="sm" spinner-small spinner-variant="primary">
    <template #overlay>
      <div class="text-center">
        <b-spinner v-show="show" type="grow" label="Loading..." />
        <h4 id="cancel-label">{{ $t("loader") }}</h4>
      </div>
    </template>
    <div class="auth-wrapper auth-v2 register-page">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="d-none d-lg-block brand-logo" :to="{ name: 'index' }">
          <img
            class="logo"
            src="@/assets/images/logo/aic.png"
            style="height: 50px"
            alt="brand-logo"
          />
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Register-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-link class="d-block d-lg-none" :to="{ name: 'index' }">
              <img
                class="logo my-3"
                src="@/assets/images/logo/aic.png"
                style="width: 90%"
                alt="brand-logo"
              />
            </b-link>

            <h2>{{ $t("register.message") }}</h2>

            <!-- form -->
            <validation-observer ref="registerForm" #default="{ invalid }">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <!-- username -->
                <b-form-group
                  :label="$t('register.username')"
                  label-for="register-username"
                >
                  <validation-provider
                    name="username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      name="username"
                      :state="errors.length > 0 ? false : null"
                      placeholder="johndoe"
                    />
                    <small
                      v-for="(error, index) in errors"
                      :key="`usrNameError${index}`"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group
                  :label="$t('register.email')"
                  label-for="register-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="email"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  :label="$t('register.password')"
                  label-for="a-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    :label="$t('register.password')"
                    vid="Password"
                    rules="required|min:8|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="a-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        :type="passwordFieldType"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  :label="$t('register.rePassword')"
                  label-for="ac-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="ac-password"
                        v-model="passValue"
                        :state="errors.length > 0 ? false : null"
                        :type="passwordConfirmFieldType"
                        placeholder="Confirm Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordConfirmToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordConfirmVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                    required
                  >
                    {{ $t("register.agree") }}
                    <b-link> {{ $t("register.privacy") }}</b-link>
                  </b-form-checkbox>
                </b-form-group>
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="warning"
                  class="warning"
                  style="color: red"
                >
                  {{ errorMessage }}
                </b-alert>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  {{ $t("register.submit") }}
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>{{ $t("register.haveAccount") }}</span>
              <b-link :to="{ name: 'auth-login' }">
                <span>&nbsp;{{ $t("register.signInInstead") }}</span>
              </b-link>
            </p>

            <!-- divider -->
            <div class="divider my-2">
              <div class="divider-text">
                {{ $t("register.or") }}
              </div>
            </div>

            <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
                @click="googleSignIn('facebook')"
              >
                <i class="fa-brands fa-facebook" />
                <!-- <feather-icon icon="FacebookIcon" /> -->
              </b-button>
              <!-- <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button> -->
              <b-button variant="google" @click="googleSignIn('google')">
                <i class="fa-brands fa-google" />
                <!-- <feather-icon icon="MailIcon" /> -->
              </b-button>
              <!-- <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <i class="fa-brands fa-linkedin" />
            </b-button> -->
            </div>
          </b-col>
        </b-col>
        <!-- /Register-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BSpinner,
  BOverlay,
  BCardText,
  BAlert,
  // BIcon,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import store from "@/store/index";
import { mapActions, mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      show: false,
      provider: null,
      user: null,
      status: "",
      username: "",
      email: "",
      password: "",
      passValue: "",
      passwordFieldType: "password",
      passwordConfirmFieldType: "password",
      sideImg: require("@/assets/images/pages/web elements-02.png"),
      required,
      errorMessage: "",
      errors: [],
      refId: "",
    };
  },
  computed: {
    role() {
      return this.$store.getters["auth/getRole"];
    },
    getUser() {
      return this.$store.getters["auth/user"];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    this.refId = this.$route.params.id;
    // console.log(localStorage.getItem("language"));

    if (localStorage.getItem("language") == "ar") {
      document.documentElement.setAttribute("dir", "rtl");
      document.body.style.fontFamily = "Tajawal";
    } else {
      document.documentElement.setAttribute("dir", "ltr");
      // document.body.style.fontFamily = 'Montserrat,Helvetica,Arial, serif'
      document.body.style.fontFamily = "nftbplus,sans-serif";
    }
  },
  methods: {
    validUsername(username) {
      return /^[a-zA-Z0-9]+$/.test(username);
    },
    async googleSignIn(type) {
      if (type === "facebook") {
        this.provider = new FacebookAuthProvider();
        this.provider.setCustomParameters({
          display: "popup",
        });
      } else if (type === "google") {
        this.provider = new GoogleAuthProvider();
      } else if (type === "linkedIn") {
        this.provider = new GoogleAuthProvider();
      }

      const auth = getAuth();
      await signInWithPopup(auth, this.provider)
        .then((result) => {
          this.show = true;
          const formdata = new FormData();
          formdata.append(
            "displayName",
            result.user.providerData[0].displayName.replace(/\s/g, "")
          );
          formdata.append("email", result.user.providerData[0].email);
          formdata.append("photoURL", result.user.providerData[0].photoURL);
          formdata.append("serviceProviderId", result.user.providerData[0].uid);
          formdata.append(
            "serviceProvider",
            type === "facebook" ? "facebook" : "google"
          );
          formdata.append("deviceType", "web");
          formdata.append("fcmId", localStorage.getItem("fcmid"));

          this.socialRegister(formdata)
            .then(() => {
              if (this.role === "Employee" || this.role === "Super-admin") {
                this.$router.replace("/dashboard");
              } else if (this.role === "Guest") {
                this.$router.replace("/account-type");
              } else if (this.role === "Partner") {
                this.$router.replace("/partner-dashboard");
              } else if (this.role === "User") {
                this.$router.push({
                  name: "userprofile",
                  params: { username: this.getUser.username },
                });
              } else {
                this.$router.replace("/index");
              }
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.$t("register.welcome")} ${
                    this.getUser.username
                  }`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `${this.$t("register.successMessage")} ${
                    this.role
                  } .${this.$t("register.successMessage2")}`,
                },
              });
            })
            .catch((error) => {
              this.show = false;
              console.log(error);
            });
        })
        .catch((error) => {
          this.show = false;
          this.errorMessage = error.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.code ? error.code : "unfortunately!",
              icon: "AlertTrainingIcon",
              variant: "danger",
              text: error.message
                ? error.message
                : "something went wrong with sign in",
            },
          });
        });
    },
    ...mapMutations({
      SET_CURRENT_VERIFY_EMAIL: "profile/SET_CURRENT_VERIFY_EMAIL",
    }),
    ...mapActions({
      registerNewAccount: "auth/register",
      socialRegister: "auth/socialRegister",
    }),
    togglePasswordConfirmVisibility() {
      this.passwordConfirmFieldType =
        this.passwordConfirmFieldType === "password" ? "text" : "password";
    },
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (!this.validUsername(this.username)) {
          this.errors.push("Valid Username required.");
        } else if (success) {
          this.errors = [];
          this.show = true;
          const formData = new FormData();
          formData.append("username", this.username);
          formData.append("email", this.email);
          formData.append("password", this.password);
          formData.append("deviceType", "web");
          formData.append("fcmId", localStorage.getItem("fcmid"));
          formData.append("confirm_password", this.password);
          formData.append("defaultLanguage", this.$store.state.locale.locale);
          formData.append("ref", this.refId);
          this.registerNewAccount(formData)
            .then((response) => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome ${this.username}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: ` ${response.data.message}`,
                },
              });
              // this.$router.replace({ name: 'auth-forgot-password-v1' })
              this.SET_CURRENT_VERIFY_EMAIL(this.email);
              localStorage.setItem("verifyEmail", this.email);
              this.$router
                .replace({ name: "auth-forgot-password-v1" })
                .catch((error) => {
                  this.errorMessage = error.response.data.error.message;
                  this.$refs.registerForm.setErrors(error.response.data.error);
                });
            })
            .catch((error) => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.error.message,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: "",
                },
              });
              this.errorMessage = error.response.data.error.message;
              this.$refs.registerForm.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.register-page label {
  font-size: 16px;
  font-weight: 500;
}
html[dir="rtl"] svg.feather {
  transform: rotate(0);
}
</style>
<style scoped>
.img-fluid {
  max-width: 56%;
}
</style>
